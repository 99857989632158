import { FormattedMessage } from 'react-intl'

export interface AdditionalStaticPage extends Omit<StaticByTitle, 'title'> {
  title: string | JSX.Element
}

export const ADDITIONAL_STATIC_PAGES: Record<string, AdditionalStaticPage> = {
  contactUs: {
    title: <FormattedMessage defaultMessage="Contact Us" />,
    slug: 'contact-us',
    visibility: 'marketplace'
  },
  blog: {
    title: <FormattedMessage defaultMessage="Blog" />,
    slug: 'blog',
    visibility: 'marketplace'
  },
  helpCenter: {
    title: <FormattedMessage defaultMessage="Help Center" />,
    slug: 'help-center',
    visibility: 'marketplace'
  }
}
