import React from 'react'

import LogoFacebook from 'app/assets/icons/footer/facebook.svg'
import LogoInstagram from 'app/assets/icons/footer/instagram.svg'
import LogoTiktok from 'app/assets/icons/footer/tiktok.svg'
import LogoYouTube from 'app/assets/icons/footer/youtube.svg'
import LogoTwitter from 'app/assets/icons/twitter.svg'
import useOpenExternalLink from 'app/hooks/useOpenExternalLink'

import IconButton from '../base/IconButton'
import Stack, { StackProps } from '../base/Stack'

const socialData = [
  {
    icon: <LogoFacebook />,
    href: 'https://www.facebook.com/Ifndautoparts/'
  },
  {
    icon: <LogoYouTube />,
    href: 'https://www.youtube.com/channel/UC5uwbMRoQcn6qK1pQYLRATQ/featured'
  },
  {
    icon: <LogoInstagram />,
    href: 'https://www.instagram.com/ifndautoparts/'
  },
  {
    icon: <LogoTiktok />,
    href: 'https://www.tiktok.com/@ifndautoparts'
  },
  {
    icon: <LogoTwitter />,
    href: 'https://twitter.com/Ifndautoparts'
  }
]

type SocialLinksBlockProps = Omit<StackProps, 'children'>

const SocialLinksBlock = (props: SocialLinksBlockProps) => {
  const { openLink } = useOpenExternalLink()

  const clickHandler = (link: string) => {
    openLink({ link, title: 'Social Media' })
  }

  return (
    <Stack direction="row" {...props}>
      {socialData.map((item, index) => (
        <IconButton
          key={index}
          aria-label="social"
          onClick={() => clickHandler(item.href)}
          sx={{ p: 0, m: 0 }}
        >
          {item.icon}
        </IconButton>
      ))}
    </Stack>
  )
}

export default SocialLinksBlock
