import { useQuery, UseQueryOptions } from 'react-query'

import {
  getAllStaticPages,
  getStaticPageBySlug
} from 'app/services/Api/staticPage'

import { staticPageKeys } from './static-pages.key'

export const staticPageConfig = {
  allStaticPages: {
    key: staticPageKeys.getAllStaticPages(),
    request: getAllStaticPages
  },
  getStaticPage: {
    key: staticPageKeys.getStaticPageBySlug,
    request: async ({ queryKey }) => {
      const [_key, _, slug] = queryKey

      const resp = await getStaticPageBySlug(slug)

      return resp
    }
  }
}

interface UseGetAllStaticPagesQuery {
  options?: UseQueryOptions<StaticPages[]>
}

export const useGetAllStaticPagesQuery = (
  props?: UseGetAllStaticPagesQuery
) => {
  const { allStaticPages: config } = staticPageConfig
  const { options } = props || {}

  const state = useQuery<StaticPages[]>(config.key, config.request, {
    ...options
  })

  return state
}

interface UseGetStaticPageQueryProps {
  slug: string
  options?: UseQueryOptions<StaticPage>
}

export const useGetStaticPageQuery = (props?: UseGetStaticPageQueryProps) => {
  const { getStaticPage: config } = staticPageConfig
  const { options, slug } = props || {}
  const state = useQuery<StaticPage>(config.key(slug), config.request, {
    ...options
  })

  return state
}
