export const staticPageKeys = {
  staticPage: ['staticPage'] as const,
  getAllStaticPages: () => [...staticPageKeys.staticPage, 'getAllStaticPages'],
  getStaticPageBySlug: (slug) => [
    ...staticPageKeys.staticPage,
    'getStaticPageBySlug',
    slug
  ],
  getAllSlugStaticPages: () => [
    ...staticPageKeys.staticPage,
    'getAllSlugStaticPages'
  ]
}
