import Link from 'next/link'
import React from 'react'

import Stack from 'app/components/base/Stack'
import Text from 'app/components/base/Text'

interface FooterLinksBlockProps {
  title: string
  items: StaticByTitle[]
}

const FooterLinksBlock: RFC<FooterLinksBlockProps> = ({ title, items }) => {
  return (
    <Stack direction="column" spacing={3}>
      <Text
        size={{ xs: 'subtitle1' }}
        sx={{
          textTransform: 'uppercase',
          fontWeight: 600,
          fontFamily: 'Nunito',
          color: 'text.third'
        }}
      >
        {title}
      </Text>

      <Stack direction="column" spacing={2}>
        {items.map((item) => (
          <Link key={item.title} prefetch={false} href={'/' + item.slug}>
            <Text
              size={{ xs: 'subtitle1' }}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'underline'
                }
              }}
            >
              {item.title}
            </Text>
          </Link>
        ))}
      </Stack>
    </Stack>
  )
}

export default FooterLinksBlock
